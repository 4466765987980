// Bootstrap and other libraries should be imported in vendor.scss in order to create a different chunk for caching purposes
// If we import bootstrap here as well we would have duplicated css increasing the page loading time,
// however, the variables scss is shared between the app and the libraries, therefore, you can use it to override variables like:
// bootstrap $primary, $secondary, $grid-gutter-width, etc.
@import "variables";

// Imports required to use bootstrap variables that are not overridden by _variables.scss
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "pages";
@import "forms";
@import "utilities";
@import "typo";
@import "backgrounds";
@import "page-designs";
@import "blocks";

html {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

@media (min-width:800px) {
  .navbar {
    // Show subitems on hover
    .dropdown:hover > .dropdown-menu {
      display: block;
      margin-top: 0; /* remove the gap so it doesn't close */
    }

    .dropdown-menu {
      margin-top: 27px !important;
    }
  }
}

@media (max-width: 799px) {
  .navbar {
    .dropdown-menu {
      margin-top: 0;
    }
  }
}


body {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', 'Helvetica Neue', 'Segoe UI', Arial, sans-serif;
  overflow-x: hidden;

  &.under-development {
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    color: white;

    .btn {
      width: 160px;
    }
  }
}

.logo {
  height: 64px;
  background-image: url("../img/othalan-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}


