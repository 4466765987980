.nav-link {
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      border-radius: 10px;
      background-color: $primary;
      transition: width 0.3s;
    }
  
    &.active{
      color: $primary !important;
    }
  
    &.active::after, &:hover::after {
      width: 100%;
    }
}

.white-nav-link {
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    border-radius: 10px;
    background-color: white;
    transition: width 0.3s;
    margin-top: 12px;
    margin-left: -14px;
  }

  &.active{
    color: white !important;
  }

  &.active::after, &:hover::after {
    width: 120%;
  }
}

.flip-card {
    perspective: 1000px;
    position: relative;
    min-height: 26rem;
  
    .card-body-front,
    .card-body-back {
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: hidden;
        transition: transform 0.6s;
    }
  
    .card-body-front {
        z-index: 2;
        transform: rotateX(0deg);
    }
  
    .card-body-back {
      z-index: 2;
        transform: rotateX(180deg);
    }
  
    &:hover .card-body-front {
        transform: rotateX(-180deg);
    }
  
    &:hover .card-body-back {
        transform: rotateX(0deg);
    }
}

.special-input{
  label{
      z-index: 2;
      margin-left: 1rem;
      padding-right: 1rem;
      padding-left: 1rem;
      position: relative;
  }
  input{
      margin-top: -10px;
      outline: none;
  }
  select{
      margin-top: -10px;
      outline: none;
  }
  textarea{
      margin-top: -10px;
  }
}

.item-btn {
    position: relative;
    display: inline-block;
    border: none;
    box-shadow: 0 8px 21px rgb(0 0 0);
    transition: all 0.3s ease-in-out;
  
    &:hover {
      background: linear-gradient(to bottom, $purple, #160935);
      transform: translateY(-2px);
    }
  
    &:active {
      transform: scale(0.95);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
}
  
.special-btn {
    position: relative;
    padding: 0.75rem 1rem;
    text-decoration: none;
    transition: 250ms ease-in-out all;
  
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: inherit;
      transition: 250ms ease-in-out opacity;
    }
}