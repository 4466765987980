
    .carousel__slide {
    padding: 5px;
    }

    .carousel__viewport {
    perspective: 2000px;
    }

    .carousel__track {
    transform-style: preserve-3d;
    }

    .carousel__slide--sliding {
    transition: 0.5s;
    }
