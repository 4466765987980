
.carousel__pagination-button::after {
  width: 9px !important;
  height: 9px !important;
  border-radius: 10px !important;
}
.carousel__prev,
.carousel__next {
  color: white !important;
}
.carousel__pagination {
  margin-top: -0.7rem !important;
  margin-bottom: 1rem !important;
}
.carousel__pagination-button::after {
  background-color: darkgrey !important;
}

.carousel__pagination-button--active::after {
  background-color: white !important;
}

.carousel__slide {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
