
.padding-login-form{
    padding: 4rem 7rem 6rem 6rem;
}

input::placeholder{
    opacity: .7;
}

.auth-bg {
  background-image: url("../img/auth-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 650px) {
    .padding-login-form{
      padding: 3rem 2rem 6rem 1rem !important;
    }
    .justify-sm-center{
      padding: 0 !important;
      display: flex;
      justify-content: center !important;
    }
    .btn{
      padding: 0.25rem !important;
    }
}