$primary: #DA8425;
$dark: #313640;
$base: #606266;
$secondary: #0D0821;
$light: #C0C4CC;
$lighter: #EBEEF5;
$purple: #2f0f69;
$aqua: #0bbae1;
$bg-card-color: #011c39;
$gray: #E1E1E1;
$orange-autumn: #e32b13;

$body-bg: $secondary;

$btn-border-radius: 20px;

// Utilities
$border-radius-sm: 0.2rem;
$border-radius: 0.6rem;
$border-radius-lg: 1.25rem;

// Inputs and buttons
$input-btn-padding-y: 0.6rem;
$input-bg: $dark;
$input-color: $secondary;
$input-btn-border-width: 0;
$input-border-radius: $border-radius-sm;
$input-padding-y: $input-btn-padding-y;
$input-placeholder-color: $light;

// Typography
$headings-font-weight: 400;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 7rem,
  8: 8rem
);

$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
  6: 5rem,
);

$enable-negative-margins: true;
