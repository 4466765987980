
.auth-page {
  hr {
    width: 5rem;
    height: 1px;
    background-color: $light;
  }

  video {
    position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;width: 100%; height: 100%; z-index: 0;
  }

  .auth-page__form {
    z-index: 1;
    height: 37rem;
    width: 57rem;
    background-image: url("../img/othalan-login-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-settings {
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (max-width: 400px) {
    .auth-page__form {
      height: 40rem;
    }
  }
  @media (max-width: 332px) {
    .auth-page__form {
      height: 47rem;
    }
  }
  @media (max-width: 305px) {
    .auth-page__form {
      height: 61rem;
    }
  }
}


