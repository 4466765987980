

.bg-body-1 {
    background-image: radial-gradient(at -115% 69%, hsl(253.22deg 100% 40%) 0, transparent 47%),
                    radial-gradient(at 138% 60%, hsl(240.23deg 66.2% 14%) 0, transparent 35%);
}

.bg-body-2 {
    background-image: radial-gradient(at -115% 38%, hsl(0deg 100% 15%) 0, transparent 50%),
                    radial-gradient(at -26% 72%, hsl(240.23deg 74.31% 24% / 81%) 0, transparent 4%);
}

.bg-color-gradient-1 {
    background-image: linear-gradient(172deg, rgb(2 2 4), rgb(15 15 29), rgb(19 19 38), rgb(27 27 54));
}

.bg-image-cover {
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-image-contain {
    background-size: contain;
    background-repeat: no-repeat;
}

.bg-contact-form{
    background-image: url("../img/contactForm.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bg-sede{
    background-image: url("../img/bg-sede.png");
    background-repeat: round;
    background-size: cover;
}

.bg-sede-2{
    background-image: url("../img/bg-sede-2.png");
    background-repeat: round;
    background-size: cover;
}

.bg-content-boxes2{
    background-image: url("../img/bg-content-boxes2.png");
    background-repeat: round;
    background-size: cover;
}

.bg-sponsors{
    background-image: url("../img/bg-sponsor.png");
    background-repeat: round;
    background-size: cover;
}

.bg-footer{
    background-image: url("../img/bg-footer.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-information-banner3{
    background-image: url("../img/bg-information-banner3.png");
    background-repeat: round;
    background-size: cover;
}

.bg-two-images{
    background-image: url("../img/bg-two-images.png");
    background-repeat: round;
    background-size: cover;
}

.bg-frecuent-questions{
    background-image: url("../img/bg-frecuent-questions.png");
    background-repeat: round;
    background-size: cover;
}

.bg-users-opinion{
    background-image: url("../img/bg-users-opinion.png");
    background-repeat: round;
    background-size: cover;
}

.footer-shadow{
    background-image: url("../img/footer-shadow.png");
    background-repeat: round;
    background-size: cover;
}

.bg-carousel-arrow{
    background-image: url("../img/carousel-arrow.png");
    background-repeat: no-repeat;
}

.bg-store-1{
    background-image: url("../img/bg-store-1.png");
    background-repeat: space;
    background-size: cover;
    z-index: 0;
}

.bg-store-2{
    background-image: url("../img/bg-store-2.png");
    background-repeat: space;
    background-size: cover;
    z-index: 1;
}

.bg-top-gradient{
    background-image: url("../img/bg-top-gradient.png");
    background-repeat: round;
    background-size: cover;
    z-index: 1;
}


.bg-bottom-gradient{
    background-image: url("../img/bg-bottom-gradient.png");
    background-repeat: round;
    background-size: cover;
    z-index: 1;
}

.bg-footer-card{
    background: #413c4b9c;
    backdrop-filter: blur(8px);
  }

.bg-detail-card{
    background-image: linear-gradient(17deg, rgb(92 89 108 / 70%), rgb(119 118 129 / 82%), rgb(115 110 135 / 78%), rgba(36, 30, 58, 0.631372549));
}

.bg-card-color{
    background-color: $bg-card-color;
}

.bg-body-transparent{
    background: linear-gradient(180deg, rgb(0, 0, 52) 0%, rgb(61 61 97 / 94%) 58%, rgb(77 86 106 / 42%) 100%);
}

.bg-orange-autumn{
    background-color: $orange-autumn;
}

.bg-gradient-purple {
    background-color: #e6d1ff7e !important;
}

.bg-gray {
    background-color: $gray !important;
    color: $dark;
}
  
.bg-image-cover {
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-h-purple-gradient {
    background: linear-gradient(to bottom, $purple, #080221);
}

.bg-v-purple-gradient {
    background: linear-gradient(to right, $purple, #080221);
}

.bg-v-transparent-gradient {
    background: linear-gradient(to right, transparent 41%, #0d0341);
}
  
.bg-gray{
    background-color: $gray !important;
    color: $dark;
}

.bg-gradient-purple {
    background-color: #e6d1ff7e !important;
}