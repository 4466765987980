@font-face {
  font-family: 'Azonix';
  src: url('../fonts/Azonix.otf') format('truetype');
}

.azonix-font {
  font-family: 'Azonix', sans-serif;
}

.aqua-neon-text {
  filter: drop-shadow(0 0 11px #07CFFF) brightness(2);
}

.red-neon-text {
  filter: drop-shadow(0 0px 10px red) brightness(77.5);
}

.white-neon-text {
  filter: drop-shadow(0 0 0 white) brightness(5.5);
}

