.max-height-25{
    max-height: 25rem;
}

.max-height-35{
    max-height: 35rem;
}

.text-end-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
}

.text-opacity-8{
    opacity: .8;
}

.w-fit-content{
    width: fit-content;
}

.h-fit-content{
    height: fit-content;
}

.letter-spacing{
    letter-spacing: 0.9px;
}

.box-shadow-gray{
    box-shadow: 3px 5px 5px #80808087
}

.box-shadow-light-gray{
    box-shadow: 1px 2px 9px rgb(128 128 128 / 52%);
}

.animation-hover {
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-2px) !important;
    }
}

.animation-hover--active {
    transform: translateY(-5px) scale(1.1);
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-7px) scale(1.1) !important;
    }
}

.all-transitions{
    transition: all 0.3s ease-in-out;
}

.accordion-button:focus{
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5) !important;
}

.accordion-button {
    &:after {
        filter: brightness(1000%);
    }

    &.collapsed::after {
        filter: brightness(1000%);
    }
}


.opacity-animation-hover{
    .secondary-content{
        opacity: 0;
    }

    &:hover {
        height: fit-content;
        border-color: #0dcaf0 !important;
    }

    &:hover .main-content{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover .secondary-content{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
}

.aqua-neon-text {
    filter: drop-shadow(0 0 4px $aqua) brightness(5.5);
}

.red-neon-text {
    filter: drop-shadow(0 0px 10px red) brightness(77.5);
}

.white-neon-text {
    filter: drop-shadow(0 0 0 white) brightness(5.5);
}

// afters
.border-aqua-neon {
    position: relative;
    text-decoration: none;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        border-radius: inherit;
        box-shadow: 0 0 0.5em 0.3em $aqua;
    }
}

.inside-neon-border {
    position: relative;
    text-decoration: none;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        border-radius: inherit;
        border: solid;
        border-width: 1px;
        border-color: darkgray;
        box-shadow: inset 0em 0em 2em 0em darkgray;
    }
}
@media (max-width: 1080px) {
    
    .img-responsive-opacity {
        opacity: 0.2;
        position: absolute;
        height: 50% !important;
    }
    .position-end{
        right: 0;
    }
    .position-start{
        left: 0;
    }
}