.duration-option, .filter-option{
    color: white;
    padding: 2px;
    transition: all ease 0.3s;
    text-decoration: none;
    display: inline-block;
}

.filter-option:hover, .duration-option:hover, .option-focus {
    transform: scale(1.3);
    color: #DA8425;
    border-bottom: 3px solid #DA8425;
}

.plan-card{
    padding: 30px 10px;
    padding-top: 60px;
    margin-bottom: 50px;
    min-width: 250px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    overflow: visible;
    transition: all 0.3s ease;
}

.plan-card:hover {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.plan-header img{
    position: absolute;
    width: 90px;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -70%);
}

.plan-price{
    display: block;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
}
